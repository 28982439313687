import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomServerDataSource } from '../modules/angular2-smart-table/directives/custom-server-data-source';
import { ServerDataSourceService } from './server-data-source.service';
import { ToastrService } from './toastr.service';
import { SmartTableConfig } from './types/common';
import {
  Contract,
  SupplierProgram,
  SupplierLoanData,
  SupplierResponse,
  LoanStatus,
  Supplier,
  SupplierRequest,
} from './types/supplier';
import { EnvironmentService } from '@shared/services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  private readonly _apiUrl = `${this.env.apiUrl}/suppliers`;

  constructor(
    private readonly env: EnvironmentService,
    private readonly httpClient: HttpClient,
    private readonly serverDataSource: ServerDataSourceService,
    private readonly toastrService: ToastrService
  ) {}

  getSupplierGrid(smartTableOptions: SmartTableConfig): CustomServerDataSource {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl}/grid`,
      'Suppliers grid request failed',
      smartTableOptions
    );
  }

  getSupplierGridFromProducerService(
    smartTableOptions?: SmartTableConfig
  ): CustomServerDataSource {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl}/producer-service/grid`,
      'Suppliers grid request failed',
      smartTableOptions || new SmartTableConfig()
    );
  }

  getSuppliers(req: SupplierRequest = {}): Observable<Supplier[]> {
    let params = new HttpParams();
    if (!(req.buyerId === undefined || req.buyerId === null)) {
      params = params.append('buyerId', req.buyerId.toString());
    }
    if (
      !(
        req.withContractsForStockTypeId === undefined ||
        req.withContractsForStockTypeId === null
      )
    ) {
      params = params.append(
        'withContractsForStockTypeId',
        req.withContractsForStockTypeId.toString()
      );
    }
    if (
      !(
        req.previousContractPeriods === undefined ||
        req.previousContractPeriods === null
      )
    ) {
      params = params.append(
        'previousContractPeriods',
        req.previousContractPeriods.toString()
      );
    }
    if (
      !(
        req.withContractsForSlaughterPeriodId === undefined ||
        req.withContractsForSlaughterPeriodId === null
      )
    ) {
      params = params.append(
        'withContractsForSlaughterPeriodId',
        req.withContractsForSlaughterPeriodId.toString()
      );
    }
    if (
      !(
        req.futureContractPeriods === undefined ||
        req.futureContractPeriods === null
      )
    ) {
      params = params.append(
        'futureContractPeriods',
        req.futureContractPeriods.toString()
      );
    }
    if (!(req.filterByName === undefined || req.filterByName === null)) {
      params = params.append('filterByName', req.filterByName.toString());
    }
    if (!(req.filterByCode === undefined || req.filterByCode === null)) {
      params = params.append('filterByCode', req.filterByCode.toString());
    }
    if (
      !(
        req.withContractsForSupplyProgramId === undefined ||
        req.withContractsForSupplyProgramId === null
      )
    ) {
      params = params.append(
        'withContractsForSupplyProgramId',
        req.withContractsForSupplyProgramId.toString()
      );
    }

    if (req.forContractsValidForSupplyPlanDate != null) {
      params = params.append(
        'forContractsValidForSupplyPlanDate',
        req.forContractsValidForSupplyPlanDate
      );
    }

    if (req.onlyGraziers) {
      params = params.append('onlyGraziers', true);
    }

    params = params.append(
      'withContractsForBuyer',
      req.withContractsForBuyer || 0
    );

    return this.httpClient.get(this._apiUrl, { params }).pipe(
      catchError(() => {
        this.toastrService.showError('Failed to retrieve suppliers');
        return of([] as any);
      })
    );
  }

  getSupplier(id): Observable<SupplierResponse> {
    return this.httpClient.get<SupplierResponse>(`${this._apiUrl}/${id}`);
  }

  getSupplierContracts(supplierContractRequest: any): Observable<Contract[]> {
    return this.httpClient.get<Contract[]>(`${this._apiUrl}/contracts`, {
      params: supplierContractRequest,
    });
  }

  updateSupplierContract(model: any): Observable<any> {
    return this.httpClient.put(`${this._apiUrl}/contracts`, model);
  }

  getSupplierContractDataSource(
    smartTableOptions: SmartTableConfig
  ): CustomServerDataSource {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl}/contracts/grid`,
      'Supplier contract grid request failed',
      smartTableOptions
    );
  }

  getSupplyPrograms(
    filterByName: string = null
  ): Observable<SupplierProgram[]> {
    let params = new HttpParams();
    if (!(filterByName === undefined || filterByName === null)) {
      params = params.append('filterByName', filterByName.toString());
    }
    return this.httpClient
      .get<any>(`${this._apiUrl}/supplyprograms`, { params: params })
      .pipe(map((response) => response.programs));
  }

  getSupplierLoans(
    id: number,
    loanStatus?: LoanStatus
  ): Observable<SupplierLoanData> {
    return this.httpClient
      .get<any>(`${this._apiUrl}/${id}/loans`, {
        params: loanStatus ? { loanStatus: loanStatus.toString() } : {},
      })
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          } else {
            this.toastrService.showError('Suppliers loans request was failed');
          }

          return of(null);
        })
      );
  }
}
