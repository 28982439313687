import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { WeekPipe } from './pipes/week-pipe.pipe';
import { TwoButtonDialogComponent } from './components/two-button-dialog/two-button-dialog.component';
import { OneButtonDialogComponent } from './components/one-button-dialog/one-button-dialog.component';
import { TextBoxDialogComponent } from './components/text-box-dialog/text-box-dialog.component';
import { SupplierPropertyNamePipe } from './pipes/supplier-property-name.pipe';
import { NgxDatePipe } from './pipes/ngx-date.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { StatusDotComponent } from './components/status-dot/status-dot.component';
import { FullAddressPipe } from '@shared/pipes/full-address.pipe';
import { DefaultIfEmptyPipe } from './pipes/default-if-empty.pipe';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { NewSupplierButtonComponent } from './components/new-supplier-button/new-supplier-button.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { IntegrationWarningWrapperComponent } from './components/integration-warning-wrapper/integration-warning-wrapper.component';
import { LazyLoadDirective } from '@shared/directives/lazy-load.directive';
import { DisableScrollInputDirective } from '@shared/directives/disable-scroll.directive';
import { CollapsibleCardComponent } from '@shared/components/collapsible-card/collapsible-card.component';
import { BlankAnchorTagComponent } from './components/blank-anchor-tag/blank-anchor-tag.component';
import { NumberOrDefaultPipe } from './pipes/number-or-default.pipe';
import { ClearIfZeroDirective } from '@shared/directives/clear-if-zero.directive';
import { SelectContractOptionsComponent } from './components/select-contract-options/select-contract-options.component';
import { DisableArrowKeysDirective } from '@shared/directives/disable-arrow-keys.directive';
import { TableNavigationDirective } from '@shared/directives/table-input-arrow-navigation';
import { FileUploadInputComponent } from '@shared/components/file-upload-input/file-upload-input.component';

const pipes = [
  KeysPipe,
  NgxDatePipe,
  SupplierPropertyNamePipe,
  WeekPipe,
  FullAddressPipe,
  DefaultIfEmptyPipe,
  NumberOrDefaultPipe,
];

@NgModule({
  declarations: [
    BlankAnchorTagComponent,
    IntegrationWarningWrapperComponent,
    TwoButtonDialogComponent,
    OneButtonDialogComponent,
    TextBoxDialogComponent,
    ...pipes,
    StatusDotComponent,
    PrintButtonComponent,
    NewSupplierButtonComponent,
    ValidationMessageComponent,
    DisableScrollInputDirective,
    ClearIfZeroDirective,
    DisableArrowKeysDirective,
    TableNavigationDirective,
    LazyLoadDirective,
    CollapsibleCardComponent,
    SelectContractOptionsComponent,
    FileUploadInputComponent,
  ],
  exports: [
    BlankAnchorTagComponent,
    IntegrationWarningWrapperComponent,
    StatusDotComponent,
    PrintButtonComponent,
    NewSupplierButtonComponent,
    ValidationMessageComponent,
    ...pipes,
    DisableScrollInputDirective,
    DisableArrowKeysDirective,
    TableNavigationDirective,
    ClearIfZeroDirective,
    LazyLoadDirective,
    CollapsibleCardComponent,
    SelectContractOptionsComponent,
    FileUploadInputComponent,
  ],
  imports: [
    FormsModule,
    NbInputModule,
    CommonModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbMomentDateModule,
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    NbCheckboxModule,
    NbFormFieldModule,
    NbPopoverModule,
  ],
})
export class SharedModule {}
